import * as React from "react"
import { graphql } from 'gatsby'

import Layout from 'templates/Layout';
import Content from 'templates/Content';
import { default as Card} from 'components/GridCard';

import DataProvider from 'providers/data';

const CaseStudyListing = props => {
	const { edges } = props.data.allContentPage;
	const nodes = edges.map(({ node }) => node);
	const { edges: caseStudyEdges } = props.data.allProjectsProject;
	let caseStudies = caseStudyEdges.map(({ node }) => node);
	let page = nodes[0];
	if ((props.providerResponse.data.length > 0 || !!caseStudies !== false) && props.providerStatusText === 'LOADED') {
		caseStudies = props.providerResponse.data;
	}

	if (page) page.gridSize = 12;

	return (
		<Layout showContactForm={page?.showContactForm} showNewsletterSignUp={page?.showNewsletterSignUp} hoverboards={page?.hoverboards}>
			<Content gridSize={page ? 12 : 8} {...page} />
			{!!caseStudies?.length && caseStudies.map((card, index) => (
				<Card {...card} link={`/case-studies/${card.permalink}/`} linkText={`Learn More`} columnSize="6" key={`caseStudy-${index}`} order={index+1} />
			))}
		</Layout>
	)
}

const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="ProjectsProject" apiParams={{_sort: 'sort'}}><CaseStudyListing {...props} /></DataProvider>
export default getData;

export const query = graphql`
{
	allContentPage(
	  filter: {uri: {eq: "/case-studies/"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
	) {
	  edges {
		node {
		  pageTitle
		  meta
		  content {
			main
		  }
		  showContactForm
		  showNewsletterSignUp
		  solutionBody
		  solutionLink
		  solutionLinkText
		  solutionTitle
		  solutionImage
		  solutions
		  headlineBody
		  headlineTitle
		  headlineImage
		  headlineType
		  hoverboards
		}
	  }
	}
	allProjectsProject {
	  edges {
		node {
		  icon
		  title
		  permalink
		  description
		  image
		}
	  }
	}
}
`