import * as React from "react"

import Link from "components/Link"

import zparse from 'helpers/zparse';

const GridCard = props => {
	let { title, description, image, order, columnSize, icon, link, linkText, colorScheme, allowImageGrowth } = props;

	if (!columnSize) columnSize = 5;
	if (!colorScheme) colorScheme = "";
	if (!allowImageGrowth) allowImageGrowth = false;

	if (!!image === false)columnSize = 12;

	return (
		<div className={`grid-container grid-card ${colorScheme}`}>
			<div className={`grid-x grid-margin-x medium-margin-collapse ${allowImageGrowth ? "align-middle" : "align-stretch"}`}>
				<div className={`cell small-12 medium-${columnSize} ${order % 2 === 0 ? "small-order-1 medium-order-2" : ""}`}>
					<div className="card-info">
						<div className="grid-x align-middle icon-wrapper">
							{icon && <img style={{ width: "50px", height: "50px" }} src={icon} alt="Card Icon" />}
							<hr />
						</div>
						<div className="header">
							{title}
						</div>
						<div className="body">
							{zparse(description)}
						</div>
						{!!link && <Link className="button" to={link}>{linkText || "Read More"}</Link>}
					</div>
				</div>
				{image && 
					<div className={`cell auto ${order % 2 === 0 ? "small-order-2 medium-order-1" : ""}`}>
						<div className="image-outer-wrapper">
							{allowImageGrowth ? <img src={image} alt={title} /> : <div className={`image-wrapper`} style={{ backgroundImage: `url(${image})` }}></div>}
						</div>
					</div>
				}
			</div>
		</div>
	)
}

export default GridCard;